<template>
  <modal-wrapper :id="id" size="md">
    <template #title>
      Nuovo plafond
    </template>
    <template #body>
      <div>
        <div>
          <div class="col-form-label">
            Banca
          </div>
          <v-select
            placeholder="Inserisci l'ABI per trovare la banca di riferimento"
            :options="options"
            v-model="currentSelection"
            :disabled="isSubmitted"
          >
            <div slot="no-options">Nessuna opzione disponibile</div>
          </v-select>
        </div>
        <div class="mt-3">
          <b-form-group label="Importo">
            <vue-numeric
              title="Importo"
              currency="€"
              v-bind:min="0"
              v-bind:max="9999999"
              separator="."
              v-model="amount"
              v-bind:minus="false"
              class="form-control"
              :state="invalidAmount"
            ></vue-numeric>
          </b-form-group>
        </div>
        <div>
          <field-calendar
            title="Data di attivazione"
            v-model="dateStart"
            :read-only="isSubmitted"
          ></field-calendar>
        </div>
        <div>
          <field-calendar
            title="Scadenza"
            v-model="dateEnd"
            :read-only="isSubmitted"
          ></field-calendar>
        </div>
        <div>
          <div class="col-form-label">
            Tipo di plafond
          </div>
          <v-select
            placeholder="Seleziona la tipologia di plafond"
            :options="plafondTypesComputed"
            v-model="plafondType"
            :disabled="isSubmitted"
          >
            <div slot="no-options">Nessuna opzione disponibile</div>
          </v-select>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="text-right">
        <b-button
          variant="success"
          @click="doSave"
          size="sm"
          :disabled="!isSaveEnabled && !isSubmitted"
        >
          Salva
          <b-spinner variant="white" class="ms-1" small v-if="isSubmitted"></b-spinner>
        </b-button>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const FieldCalendar = () => import('@/components/fields/FieldCalendar.vue');

export default {
  name: 'ModalAddPlafond',
  props: {
    id: String,
    entityType: String,
    onSuccess: Function,
  },
  components: {
    ModalWrapper,
    FieldCalendar,
  },
  data() {
    const currentProfile = this.$store.getters['subject/currentCompany'];
    let companyId = '';
    if (isNotEmpty(currentProfile)) {
      companyId = currentProfile.companyId;
    }
    return {
      submitted: false,
      currentSelection: '',
      options: [],
      hasErrors: false,
      error: null,
      amount: '',
      dateStart: null,
      dateEnd: null,
      plafondType: '',
      companyIdData: companyId,
    };
  },
  computed: {
    isSaveEnabled() {
      const a = isNotEmpty(this.currentSelection) && this.currentSelection !== '';
      const b = isPresent(this.amount);
      const c = isPresent(this.dateStart);
      const d = isPresent(this.dateEnd);
      const e = isNotEmpty(this.plafondType) && this.plafondType !== '';
      console.log('isSaveEnabled', a, b, c, d, e);
      return a && b && c && d && e;
    },
    plafondTypesComputed() {
      console.log('plafond types', this.$store.getters['tableUtils/getPlafondTypes']);
      return this.$store.getters['tableUtils/getPlafondTypes'] || [];
    },
    isSubmitted() {
      return this.$store.getters['invoice/isLoading'];
    },
  },
  mounted() {
    this.buildOptions();
  },
  methods: {
    buildOptions() {
      const a = this.$store.getters['tableUtils/getAbiList'];
      a.forEach((item) => this.options.push({
        key: item.abi,
        label: `${item.abi} - ${item.description}`,
      }));
    },
    doSave() {
      console.log('saving ');
      const request = {
        ownerId: this.companyIdData,
        bankId: this.currentSelection.key,
        validityStart: this.dateStart,
        validityEnd: this.dateEnd,
        globalPlafondLimit: this.amount,
        plafondType: this.plafondType.key,
      };
      this.$store.dispatch('invoice/savePlafond', request).then(
        (response) => {
          console.log('save response', response);
          if (this.onSuccess) {
            this.onSuccess();
          }
        },
        (error) => {
          console.log('save error', error);
        },
      );
    },
    invalidAmount() {
      return this.amount <= 0;
    },
  },
};
</script>

<style scoped>

</style>
